// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-angebote-js": () => import("./../../../src/pages/angebote.js" /* webpackChunkName: "component---src-pages-angebote-js" */),
  "component---src-pages-fotogalerie-js": () => import("./../../../src/pages/fotogalerie.js" /* webpackChunkName: "component---src-pages-fotogalerie-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-uberuns-js": () => import("./../../../src/pages/uberuns.js" /* webpackChunkName: "component---src-pages-uberuns-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

